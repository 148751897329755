<template>
  <div class="text-center m-auto">
    <div
      :style="{ width: width == null ? '100%' : width }"
      class="
        d-flex
        justify-content-around
        align-items-center
        input-series
        m-auto
      "
      :ref="`input-series-container_${seriesId}`"
    >
      <template v-for="(n, idx) in number">
        <template v-if="n > 1">
          <div :key="`${seriesId}_divider_${idx}`">-</div>
        </template>
        <div
          class="input-series-item"
          :key="`${seriesId}_input_container_${idx}`"
        >
          <input
            :ref="`${seriesId}_input_${idx}`"
            type="text"
            class="single-char-input"
            :class="[
              { success: state == 'success' },
              { failed: state == 'failed' },
            ]"
            v-model.trim="series[idx]"
            maxlength="2"
            @input="onInput($event, idx)"
            @keydown.delete="deleteItem(idx)"
            @click="focusLastChar($event, idx)"
            :disabled="locked"
          />
        </div>
      </template>

      <!-- <div class="input-series-item">
        <input type="text" class="single-char-input" />
      </div>
      <div>-</div>
      <div class="input-series-item">
        <input type="text" class="single-char-input" />
      </div>
      <div>-</div>
      <div class="input-series-item">
        <input type="text" class="single-char-input" />
      </div>
      <div>-</div>
      <div class="input-series-item">
        <input type="text" class="single-char-input" />
      </div>
      <div>-</div>
      <div class="input-series-item">
        <input type="text" class="single-char-input" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    seriesId: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      default: 6,
    },
    width: {
      type: String,
      default: null,
    },
    autoSkipCell: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    series: [],
    locked: false,
    state: "",
  }),
  created() {
    this.init();
  },
  mounted() {
    if (this.autoFocus) {
      this.focusFirst();
    }
  },
  watch: {
    series: {
      deep: true,
      handler(val) {
        this.monitorValues(val);
      },
    },
  },
  computed: {
    items() {
      let inputsContainer = this.$refs.container;
      let inputs = inputsContainer.querySelectorAll(".input-series-item");
      return inputs;
    },
  },
  beforeDestroy() {
    // destroy event
    this.destroy();
  },
  methods: {
    init() {
      this.series = [];
      // generate default value for v-model
      for (let index = 0; index < this.number; index++) {
        this.series.push("");
      }
    },
    focusFirst() {
      this.tryFocusNext(-1);
    },
    destroy() {
      this.locked = false;
      this.series = [];
    },
    onInput(e, current) {
      if (this.series[current] == "") return;
      else {
        this.series[current] = e.target.value[e.target.value.length - 1];
      }

      this.tryFocusNext(current);
    },
    focusLastChar(e, idx) {
      let oldVal = this.series[idx];
      e.target.value = "";
      this.$nextTick(() => {
        e.target.value = oldVal;
      });
    },
    tryFocusNext(current) {
      let nextIdx = current + 1;
      if (this.series[nextIdx] != "" && this.autoSkipCell == true) {
        this.tryFocusNext(nextIdx);
      } else {
        let refName = `${this.seriesId}_input_${nextIdx}`;
        if (this.$refs[refName] != null) {
          setTimeout(() => {
            this.$refs[refName][0].focus();
          });
        }
      }
    },
    deleteItem(current) {
      // console.log(this.series[current] == "");
      if (this.series[current] != "") {
        this.series[current] = ""; // clear current
        return;
      }

      // console.log("val", this.series[current], this.series[current] == "");
      // if (this.series[current] == "") return;

      let nextIdx = current - 1;
      this.series[nextIdx] = "";
      let refName = `${this.seriesId}_input_${nextIdx}`;
      if (this.$refs[refName] != null) {
        setTimeout(() => {
          this.$refs[refName][0].focus();
        });
      }
    },
    monitorValues(val) {
      let isFilled = true;
      for (const item of val) {
        if (item == null || item == "") {
          isFilled = false;
          break;
        }
      }
      if (isFilled) {
        this.locked = true;
        let token = val.join("");
        this.$emit("filled", token);
      }
    },
    clear() {
      for (let index = 0; index < this.number; index++) {
        this.series[index] = "";
      }
      this.locked = false;
      this.$nextTick(() => {
        this.tryFocusNext(-1);
      }, 100);

      setTimeout(() => {
        this.state = "";
      }, 2 * 1000);
    },
    success() {
      this.state = "success";
    },
    failed() {
      this.state = "failed";
    },
  },
};
</script>

<style scoped>
.single-char-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  text-align: center;
  border: 1px solid grey;
  text-transform: uppercase;
}

.single-char-input.success {
  border: 1px solid #28a745;
}
.single-char-input.failed {
  border: 1px solid #dc3545;
}
</style>