import Vue from "vue"

export const twoFactorAuthenOptions = [
    { text: "ปิดการใช้งาน", value: null },
    { text: "Authenticator", value: "authenticator" },
    { text: "Mobile SMS", value: "sms-otp" },
    { text: "E-Mail", value: "mail" }
]

export const getSecurityConfig = function () {
    return Vue.axios.get(`/account/security/config`).then(resp => {
        return resp.data
    })
}

/**
 * 
 * @param {*} value 
 * @returns {Promise}
 */
export const updateTwoFactorsAuth = function (value) {
    return Vue.axios.patch(`/account/security/config`, {
        method: value
    }).then(resp => {
        return resp.data
    })
}

//#region authenticator
/**
 * 
 * @returns {Promise}
 */
const verifyAuthenticatorToken = function (token, mode = "new") {
    return Vue.axios.post(`/account/security/authenticator/verify`, { token, mode }).then((resp) => {
        return resp.data
    })
}

/**
 * 
 * @returns {Promise}
 */
const getAuthenticatorShareSecret = function () {
    return Vue.axios.get(`/account/security/authenticator/share-secret`).then((resp) => {
        return resp.data
    })
}

/**
 * 
 * @returns {Promise<String>}
 */
const getAuthenticatorQrCode = function () {
    return Vue.axios.get(`/account/security/authenticator/qrcode`, {
        responseType: "blob"
    }).then((resp) => {
        return URL.createObjectURL(resp.data) // return blob url
    })
}

/**
 * 
 * @returns {Promise}
 */
const deleteAuthenticatorConfig = function () {
    return Vue.axios.delete(`/account/security/authenticator`).then((resp) => {
        return resp.data // return blob url
    })
}

export const authenticatorService = {
    getShareSecret: getAuthenticatorShareSecret,
    verify: verifyAuthenticatorToken,
    qrcode: getAuthenticatorQrCode,
    remove: deleteAuthenticatorConfig
}
//#endregion authenticator

//#region mobile

/**
 * 
 * @param {string} [mobileNo=] - mobile number in E.164 format
 * @param {boolean} [forceMode=false] 
 * @returns {Promise}
 */
const reqTOTP = function (mobileNo = "", forceMode = false) {
    let payload = {
        mobileNo: mobileNo
    }
    if (forceMode == true) {
        payload.force = "true"
    }
    return Vue.axios.post(`/account/security/mobile/totp`, payload).then((resp) => {
        return resp.data
    })
}

/**
 * 
 * @param {*} token 
 * @param {boolean} [create=false]
 * @returns {Promise}
 */
const verifySMSTOTP = function (token, create = false) {
    let payload = {
        token
    }
    if (create == true) {
        payload.create = "true"
    }
    return Vue.axios.post(`/account/security/mobile/totp/verify`, payload).then((resp) => {
        return resp.data
    })
}

/**
 * 
 * @param {*} mobileNo 
 * @returns {Promise}
 */
const verifyOldMobileNo = function (mobileNo) {
    return Vue.axios.post(`/account/security/mobile/verify`, { mobileNo }).then((resp) => {
        return resp.data
    })
}

export const mobile = {
    reqTOTP,
    verifyTOTP: verifySMSTOTP,
    verifyOldMobileNo
}
//#endregion mobile

//#region email

const reqEmailTOTP = function () {
    return Vue.axios.get(`/account/security/mail/totp`).then(resp => {
        return resp.data
    })
}

/**
 * 
 * @param {*} token 
 * @param {boolean} [save=false] 
 * @returns 
 */
const verifyEmailTOTP = function (token, save = false) {
    let payload = {
        token
    }
    if (save) {
        payload.save = "true"
    }
    return Vue.axios.post(`/account/security/mail/totp/verify`, payload).then((resp) => {
        return resp.data
    })
}

export const email = {
    reqTOTP: reqEmailTOTP,
    verifyTOTP: verifyEmailTOTP
}
//#endregion

//#region income config
export const threeFactorsState = function () {
    this.email = {
        refNo: null,
        token: null,
        verified: false
    }

    this.mobile = {
        refNo: null,
        token: null,
        verified: false
    }

    this.authenticator = {
        token: null,
        verified: false
    }
}

/**
 * @typedef IncomeConfig
 * @type {object}
 * @property {string} publicKey
 * @property {string} secretKey
 */

/**
 * 
 * @param {IncomeConfig} incomeConfig 
 * @returns {Promise}
 */
export const updateIncomeConfig = function (incomeConfig) {
    return Vue.axios.post(`/company/income/config`, incomeConfig).then(resp => {
        return resp.data
    })
}

/**
 * 
 * @returns {Promise}
 */
export const getIncomeConfig = function () {
    return Vue.axios.get(`/company/income/config`).then(resp => {
        return resp.data
    })
}
//#endregion

export default { email, mobile, authenticatorService, getSecurityConfig, updateTwoFactorsAuth }